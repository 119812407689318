
import EmployeeList from '@/components/employees/EmployeeList.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { EmployeeList },
  metaInfo: {
    title: 'Employee - '
  },
  name: 'Employee',
  computed: {}
});
